/**
 * @fileoverview Utility for consistent URL formatting across the application.
 * Provides standardized handling of protocols, trailing slashes, and other URL components.
 */

interface FormatURLOptions {
  /**
   * Whether to force HTTPS protocol. Default: true
   */
  forceHttps?: boolean
  /**
   * Whether to keep trailing slashes. Default: false (removes them)
   */
  keepTrailingSlash?: boolean
  /**
   * Whether to preserve query parameters. Default: true
   */
  preserveQuery?: boolean
  /**
   * Whether to preserve URL fragments. Default: true
   */
  preserveFragment?: boolean
}

/**
 * Default options for URL formatting
 */
const defaultOptions: FormatURLOptions = {
  forceHttps: true,
  keepTrailingSlash: false,
  preserveQuery: true,
  preserveFragment: true,
}

/**
 * Formats a URL according to application standards.
 *
 * Features:
 * - Protocol normalization (defaults to https://)
 * - Trailing slash management (defaults to removing them)
 * - Path normalization (removes duplicate slashes)
 * - Query parameter preservation/removal
 * - Fragment preservation/removal
 *
 * @param url The URL to format
 * @param options Configuration options for formatting behavior
 * @returns A properly formatted URL string or null if the input is invalid
 *
 * @example
 * // Returns "https://example.com/path"
 * formatURL("http://example.com/path/")
 *
 * @example
 * // Returns "http://example.com/path/"
 * formatURL("http://example.com/path/", { forceHttps: false, keepTrailingSlash: true })
 */
export function formatURL(url: string, options?: FormatURLOptions): string | null {
  try {
    if (!url || typeof url !== 'string') {
      return null
    }

    // Merge provided options with defaults
    const opts = { ...defaultOptions, ...options }

    // Handle URLs that might be missing protocol (e.g., "example.com/path")
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = `https://${url}`
    }

    const parsedUrl = new URL(url)

    // Protocol normalization
    if (opts.forceHttps) {
      parsedUrl.protocol = 'https:'
    }

    // Path normalization - first ensures we don't have double slashes
    // This uses a regex to replace any instances of multiple slashes with a single slash
    // but doesn't affect the protocol's double slash
    let normalizedPath = parsedUrl.pathname.replace(/\/{2,}/g, '/')

    // Trailing slash handling
    // Remove trailing slash if keepTrailingSlash is false
    if (!opts.keepTrailingSlash && normalizedPath.endsWith('/')) {
      normalizedPath = normalizedPath.replace(/\/+$/, '')
    } else if (opts.keepTrailingSlash && !normalizedPath.endsWith('/')) {
      normalizedPath = `${normalizedPath}/`
    }

    parsedUrl.pathname = normalizedPath

    // Hostname normalization removed

    // Remove query parameters if not preserving
    if (!opts.preserveQuery) {
      parsedUrl.search = ''
    }

    // Remove fragment if not preserving
    if (!opts.preserveFragment) {
      parsedUrl.hash = ''
    }

    return parsedUrl.toString()
  } catch (error: unknown) {
    console.error('Error formatting URL:', error)
    return null
  }
}
