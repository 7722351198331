// This is a server component (no directive)

import { Button, type ButtonProps } from '@/components/ui/Button/index'
import { cn } from '@/utils/ui'
import Link from 'next/link'
import React from 'react'

import type { Page, Play, Post, Project, Service, Team } from '@/payload-types'

export type CMSLinkType = {
  ui?: 'inline' | ButtonProps['variant']
  children?: React.ReactNode
  className?: string
  label?: string | null
  newTab?: boolean | null
  reference?: {
    relationTo: 'pages' | 'posts' | 'play' | 'services' | 'projects' | 'team'
    value: Page | Post | Project | Service | Play | Team | string | number
  } | null
  size?: ButtonProps['size'] | null
  type?: 'custom' | 'reference' | 'email' | 'phone' | null
  url?: string | null
  // Client-side event handler ids for hydration
  eventHandlerId?: string
}

// Helper to detect client-side behaviors
const hasClientBehaviors = (props: CMSLinkType): boolean => {
  return !!props.eventHandlerId
}

// Generate the href from props
export const getLinkHref = (props: Pick<CMSLinkType, 'type' | 'reference' | 'url'>): string => {
  const { type, reference, url } = props

  return type === 'reference' && typeof reference?.value === 'object' && reference.value !== null
    ? `${reference?.relationTo !== 'pages' ? `/${reference?.relationTo}` : ''}/${
        'slug' in reference.value ? reference.value.slug : ''
      }`
    : url || ''
}

/**
 * CMSLink component for rendering links that can be either regular or interactive
 * For links with client-side behaviors (using eventHandlerId),
 * we use a client component that's imported where needed.
 */
export const CMSLink: React.FC<CMSLinkType> = (props) => {
  // If client-side behaviors are needed, render a marker for hydration
  if (hasClientBehaviors(props)) {
    // This will be replaced client-side by the ClientCMSLink
    return (
      <span
        data-cms-link="client"
        data-props={JSON.stringify(props)}
        className={props.className}
        style={{ display: 'contents' }}
      >
        {props.label || props.children}
      </span>
    )
  }

  const {
    type,
    ui = 'inline',
    children,
    className,
    label,
    newTab,
    reference,
    size: sizeFromProps,
    url,
  } = props

  const href = getLinkHref({ type, reference, url })

  if (!href && !url) return null

  const newTabProps = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}

  /* Ensure we don't break any styles set by richText */
  if (ui === 'inline') {
    return (
      <Link className={cn(className)} href={href} {...newTabProps}>
        {label && label}
        {children && children}
      </Link>
    )
  }

  return (
    <Button asChild className={className} size={sizeFromProps ?? 'default'} variant={ui}>
      <Link className={cn(className)} href={href} {...newTabProps}>
        {label && label}
        {children && children}
      </Link>
    </Button>
  )
}
