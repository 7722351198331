/**
 * @fileoverview Utilities for determining the application's base URL in different environments.
 * Handles URL generation for both server-side and client-side contexts with fallbacks.
 */

import { formatURL } from './formatURL'
import { canUseDOM } from '@/utils/dom'

/**
 * Gets the client-side site URL.
 * Prioritizes:
 * 1. NEXT_PUBLIC_SITE_URL (set via populateSiteConfigEnv, preferred for client-side access)
 * 2. Default development URL (http://localhost:3000)
 */
export const getURL = (): string => {
  const rawUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000'
  // Format the URL consistently, removing trailing slash and respecting protocol
  return formatURL(rawUrl, { keepTrailingSlash: false, forceHttps: false }) || rawUrl
}

/**
 * Gets the server-side site URL.
 * Prioritizes:
 * 1. SITE_URL environment variable (preferred for server-side/build)
 * 2. NEXT_PUBLIC_SITE_URL environment variable (fallback, populated from dynamic SiteConfig)
 * 3. Default development URL (http://localhost:3000) as a last resort
 */
export const getServerSideURL = (): string => {
  const rawUrl = process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000'
  // Format the URL consistently, removing trailing slash and respecting protocol
  return formatURL(rawUrl, { keepTrailingSlash: false, forceHttps: false }) || rawUrl
}

/**
 * Determines the application's base URL for client-side operations.
 * Adapts to the current environment and handles both browser and server contexts.
 *
 * Priority order:
 * 1. Current window location (if in browser context)
 * 2. SITE_URL environment variable (if in SSR/server context)
 * 3. NEXT_PUBLIC_SITE_URL environment variable (fallback if SITE_URL is not set in SSR)
 * 4. Default development URL (http://localhost:3000) as final fallback
 *
 * @returns {string} The client-side base URL
 *
 * @example
 * const clientURL = getClientSideURL()
 * // In browser: Returns current origin (e.g., "https://your-domain.com")
 * // In SSR: Returns configured URL from environment
 */
export const getClientSideURL = (): string => {
  // Use current window location if in browser
  if (canUseDOM()) {
    const protocol = window.location.protocol
    const domain = window.location.hostname
    const port = window.location.port

    const origin = `${protocol}//${domain}${port ? `:${port}` : ''}`
    // Format the origin consistently (though usually not needed for origin)
    return formatURL(origin, { keepTrailingSlash: false, forceHttps: false }) || origin
  }

  // Fall back to configured server URL or site config
  const rawUrl = process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000'
  // Format the URL consistently, removing trailing slash and respecting protocol
  return formatURL(rawUrl, { keepTrailingSlash: false, forceHttps: false }) || rawUrl
}
