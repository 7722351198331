import React from 'react'
import { cn } from '@/utils/ui'
import type { RichVideoBlock as RichVideoProps } from '@/payload-types'
// Import is commented out while we use the simpler iframe approach
// import { Video } from '@/components/core/Video'

type Props = {
  className?: string
} & RichVideoProps

/**
 * RichVideo component for embedding videos in content
 *
 * CURRENT IMPLEMENTATION: Simple iframe embed
 * TODO: Re-enable Vidstack player when React 19/Next 15 compatibility issues are resolved
 */
export const RichVideo: React.FC<Props> = (props) => {
  const { className, content, media } = props

  if (!content || !content.videoUrl) {
    return null
  }

  const { videoUrl, videoTitle } = content

  // Handle poster if it exists
  const videoPoster = media?.videoPoster
  const _posterUrl = videoPoster && typeof videoPoster === 'object' ? videoPoster.url : undefined

  // Create embed URL from video URL
  const getEmbedUrl = (url: string): string => {
    // Process YouTube URLs
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const youtubeRegex =
        /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
      const match = url.match(youtubeRegex)
      const id = match?.[1]

      if (id) {
        return `https://www.youtube.com/embed/${id}?autoplay=0&mute=0&controls=1`
      }
    }

    // Process Vimeo URLs
    if (url.includes('vimeo.com')) {
      const vimeoRegex =
        /vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(\d+)(?:|\/\?)/
      const match = url.match(vimeoRegex)
      const id = match?.[1]

      if (id) {
        return `https://player.vimeo.com/video/${id}?autoplay=0&muted=0&controls=1`
      }
    }

    // If it's already an embed URL, return it as is
    if (url.includes('embed') || url.includes('player')) {
      return url
    }

    // For direct video files, return as is
    if (url.match(/\.(mp4|webm|ogg)$/i)) {
      return url
    }

    // Default fallback
    return url
  }

  const embedUrl = getEmbedUrl(videoUrl)

  return (
    <div className={cn('my-8 w-full', className)}>
      <div className="relative w-full overflow-hidden pt-[56.25%]">
        <iframe
          src={embedUrl}
          className="absolute inset-0 h-full w-full"
          title={videoTitle || 'Embedded video'}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  )
}

/* Original implementation using Vidstack, commented out due to React 19/Next 15 compatibility issues
export const RichVideo: React.FC<Props> = (props) => {
  const { className, content, media } = props

  if (!content || !content.videoUrl) {
    return null
  }

  const { videoUrl, videoTitle } = content

  // Handle poster if it exists
  const videoPoster = media?.videoPoster
  const posterUrl = videoPoster && typeof videoPoster === 'object' ? videoPoster.url : undefined

  // Using a temporary type assertion to make TypeScript happy
  const VideoComponent = Video as any

  return (
    <div className={cn('my-8 w-full', className)}>
      <VideoComponent
        src={videoUrl}
        title={videoTitle || 'Video'}
        poster={posterUrl}
        className="w-full"
        useCustomPlayer={true}
        debug={true}
      />
    </div>
  )
}
*/
