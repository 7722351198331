import React from 'react'
import { cn } from '@/utils/ui'
import type { RichMediaBlock as RichMediaProps } from '@/payload-types'
import { Media } from '@/components/core/Media'
import { RichText } from '@/components/core/RichText'

type Props = {
  className?: string
} & RichMediaProps

export const RichMedia: React.FC<Props> = (props) => {
  const { className, media, caption } = props

  if (!media) {
    return <div>No media data available</div>
  }

  return (
    <div className={cn('', className)}>
      <div className="relative">
        <Media resource={media} />
      </div>
      {caption && (
        <div className="mt-2 text-sm text-gray-600">
          <RichText data={caption} enableGutter={false} />
        </div>
      )}
    </div>
  )
}
