import React from 'react'
import type { RichCTABlock as RichCTAProps } from '@/payload-types'
import { CMSLink } from '@/components/core/CMSLink'
import { RichText } from '@/components/core/RichText'
import { cn } from '@/utils/ui'

type Props = {
  className?: string
} & RichCTAProps

export const RichCTA: React.FC<Props> = (props) => {
  const { links, richText, className } = props

  return (
    <div className={cn('container', className)}>
      <div className="bg-card border-border flex flex-col gap-8 rounded border p-4 md:flex-row md:items-center md:justify-between">
        <div className="flex max-w-[48rem] items-center">
          {richText && <RichText className="mb-0" data={richText} enableGutter={false} />}
        </div>
        <div className="flex flex-col gap-8">
          {(links || []).map(({ link }, i) => {
            return <CMSLink key={i} size="lg" {...link} ui={link.ui || undefined} />
          })}
        </div>
      </div>
    </div>
  )
}
