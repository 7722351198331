'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown, Plus, Minus } from 'lucide-react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/utils/ui'
import { RichText } from '@/components/core/RichText'

const accordionVariants = cva(
  'flex w-full items-center justify-between py-4 text-left transition-all cursor-pointer text-white',
  {
    variants: {
      variant: {
        default: 'text-base font-medium',
        heading: 'text-xl font-semibold',
        large: 'text-2xl font-bold',
      },
      size: {
        default: 'gap-4',
        sm: 'gap-2 py-2',
        lg: 'gap-6 py-6',
      },
      iconVariant: {
        chevron: '[&[data-state=open]>svg]:rotate-180',
        plus: '[&[data-state=open]>svg]:hidden [&[data-state=open]>.minus]:block',
      },
      layout: {
        default: '',
        table: 'border-t border-[var(--color-border)] first:border-t-0',
        tabbed:
          'border border-[var(--color-border)] rounded-md px-4 mb-2 bg-[var(--color-background-subtle)]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      iconVariant: 'chevron',
      layout: 'default',
    },
  },
)

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Root ref={ref} className={cn('w-full', className)} {...props} />
))
Accordion.displayName = 'Accordion'

type AccordionItemContextValue = {
  variant?: VariantProps<typeof accordionVariants>['variant']
  size?: VariantProps<typeof accordionVariants>['size']
  iconVariant?: VariantProps<typeof accordionVariants>['iconVariant']
  layout?: VariantProps<typeof accordionVariants>['layout']
  triggerClassName?: string
  contentClassName?: string
  headerClassName?: string
}

const AccordionItemContext = React.createContext<AccordionItemContextValue>({})

interface AccordionItemProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {
  triggerVariant?: VariantProps<typeof accordionVariants>['variant']
  triggerSize?: VariantProps<typeof accordionVariants>['size']
  iconVariant?: VariantProps<typeof accordionVariants>['iconVariant']
  layout?: VariantProps<typeof accordionVariants>['layout']
  triggerClassName?: string
  contentClassName?: string
  headerClassName?: string
}

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  AccordionItemProps
>(
  (
    {
      className,
      triggerVariant,
      triggerSize,
      iconVariant,
      layout,
      triggerClassName,
      contentClassName,
      headerClassName,
      children,
      ...props
    },
    ref,
  ) => (
    <AccordionItemContext.Provider
      value={{
        variant: triggerVariant,
        size: triggerSize,
        iconVariant,
        layout,
        triggerClassName,
        contentClassName,
        headerClassName,
      }}
    >
      <AccordionPrimitive.Item ref={ref} className={cn('w-full', className)} {...props}>
        {children}
      </AccordionPrimitive.Item>
    </AccordionItemContext.Provider>
  ),
)
AccordionItem.displayName = 'AccordionItem'

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>,
    VariantProps<typeof accordionVariants> {
  headerClassName?: string
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, variant, size, iconVariant, layout, headerClassName, ...props }, ref) => {
  const contextValues = React.useContext(AccordionItemContext)

  const resolvedVariant = variant || contextValues.variant
  const resolvedSize = size || contextValues.size
  const resolvedIconVariant = iconVariant || contextValues.iconVariant || 'chevron'
  const resolvedLayout = layout || contextValues.layout
  const resolvedHeaderClassName = headerClassName || contextValues.headerClassName

  return (
    <AccordionPrimitive.Header className={cn('flex', resolvedHeaderClassName)}>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          accordionVariants({
            variant: resolvedVariant,
            size: resolvedSize,
            iconVariant: resolvedIconVariant,
            layout: resolvedLayout,
          }),
          contextValues.triggerClassName,
          className,
        )}
        {...props}
      >
        {children}
        {resolvedIconVariant === 'chevron' && (
          <ChevronDown className="h-4 w-4 shrink-0 text-[var(--color-muted)] transition-transform duration-200" />
        )}
        {resolvedIconVariant === 'plus' && (
          <>
            <Plus className="h-4 w-4 shrink-0 text-[var(--color-muted)]" />
            <Minus className="minus hidden h-4 w-4 shrink-0 text-[var(--color-muted)]" />
          </>
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = 'AccordionTrigger'

interface AccordionContentProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> {
  richText?: boolean
}

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  AccordionContentProps
>(({ className, children, richText = false, ...props }, ref) => {
  const contextValues = React.useContext(AccordionItemContext)

  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn(
        'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-[var(--color-muted)] transition-all',
        contextValues.contentClassName,
        className,
      )}
      {...props}
    >
      <div className="pt-0 pb-4">
        {richText ? <RichText className="space-y-4">{children}</RichText> : children}
      </div>
    </AccordionPrimitive.Content>
  )
})
AccordionContent.displayName = 'AccordionContent'

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }

// If we need server-specific logic in the future, it would go here
// For example:
// export function ServerEnhancedAccordion(props) {
//   // Server-side data preparation or logic
//   return <Accordion {...props} />
// }
