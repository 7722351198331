import { cn } from '@/utils/ui'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

/**
 * @file Button Component
 * @description A reusable button component built with Radix UI and Tailwind CSS.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing button styles and variants.
 * It supports different sizes and variants, and can be rendered as a child element using the `asChild` prop.
 *
 * @see ButtonProps for a list of available props
 *
 * @see {@link https://www.radix-ui.com/docs/primitives/components/button Radix UI Button}
 * @see {@link https://tailwindcss.com/ Tailwind CSS}
 *
 * @returns {React.ReactElement} A button element with the specified styles and variants.
 */

/**
 * @interface ButtonProps
 * @description Defines the props for the Button component.
 * @extends React.ButtonHTMLAttributes<HTMLButtonElement>
 * @extends VariantProps<typeof buttonVariants>
 *
 * @property {boolean} [asChild=false] - Whether to render the button as a child element.
 * @property {string} [className] - Additional CSS classes to apply to the button.
 * @property {('clear' | 'default' | 'icon' | 'lg' | 'sm')} [size='default'] - The size of the button.
 * @property {('primary' | 'secondary' | 'link')} [variant='primary'] - The variant of the button.
 */
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  className?: string
  size?: 'clear' | 'default' | 'icon' | 'lg' | 'sm'
  variant?: 'primary' | 'secondary' | 'link'
}

/**
 * @const buttonVariants
 * @description Defines the style variants for the Button component using `class-variance-authority`.
 *
 * @remarks
 * This allows for easy modification and extension of button styles based on different properties.
 *
 * @defaultVariants
 * ```json
 * {
 *   size: 'default',
 *   variant: 'primary',
 * }
 * ```
 *
 * @variants
 * - `size`: Defines the size of the button.
 *   - `clear`: Removes all styling.
 *   - `default`: The default button size.
 *   - `icon`: A small button for icons.
 *   - `lg`: A large button.
 *   - `sm`: A small button.
 * - `variant`: Defines the visual style of the button.
 *   - `primary`: The primary button style.
 *   - `secondary`: A secondary button style.
 *   - `link`: A link button style.
 */
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-sm font-bold uppercase ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      size: 'default',
      variant: 'primary',
    },
    variants: {
      size: {
        clear: '',
        default: 'h-[3.75rem] px-4 min-w-[180px]',
        icon: 'h-10 w-10',
        lg: 'h-[4.25rem] px-8 min-w-[240px] text-base',
        sm: 'h-8 px-3 text-xs',
      },
      variant: {
        primary:
          'bg-[oklch(71.5%_0.15_80)] text-black hover:bg-[oklch(10%_0_0)] hover:text-[oklch(71.5%_0.15_80)] rounded-xs transition-colors duration-300',
        secondary:
          'border-1 border-white text-white hover:bg-white hover:text-black rounded-xs transition-colors duration-300',
        link: 'relative inline-block min-w-max text-foreground items-start justify-start after:absolute after:bottom-0 after:left-0 after:w-0 after:bg-current after:transition-all after:duration-200 hover:after:w-full after:h-[1px]',
      },
    },
  },
)

const ButtonServer = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, className, size, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp className={cn(buttonVariants({ className, size, variant }))} ref={ref} {...props} />
    )
  },
)

ButtonServer.displayName = 'Button'

export { ButtonServer as Button, buttonVariants }
