import React from 'react'
import type { RichBeforeAfterBlock as RichBeforeAfterProps } from '@/payload-types'
import type { Media } from '@/payload-types'
import { RichBeforeAfterComponent } from '@/blocks/RichBeforeAfter/Component.client'

type Props = {
  className?: string
} & RichBeforeAfterProps

export const RichBeforeAfter: React.FC<Props> = (props) => {
  const { beforeImage, afterImage, backgroundColor = 'bg-neutral-950' } = props

  const beforeImageUrl = typeof beforeImage === 'string' ? beforeImage : beforeImage?.url
  const afterImageUrl = typeof afterImage === 'string' ? afterImage : afterImage?.url
  const beforeImageAlt =
    typeof beforeImage === 'string' ? 'Before image' : (beforeImage as Media)?.alt || 'Before image'
  const afterImageAlt =
    typeof afterImage === 'string' ? 'After image' : (afterImage as Media)?.alt || 'After image'

  if (!beforeImageUrl || !afterImageUrl) return null

  return (
    <RichBeforeAfterComponent
      beforeImage={beforeImageUrl}
      afterImage={afterImageUrl}
      beforeImageAlt={beforeImageAlt}
      afterImageAlt={afterImageAlt}
      backgroundColor={backgroundColor}
    />
  )
}
