/**
 * Server component for image rendering with Cloudflare optimization.
 * Uses Next.js Image with a global custom loader for Cloudflare.
 *
 * Note: Removed client-side features (onClick, onLoad) for better performance
 * as they weren't being used. If needed later, these can be added back via:
 * - Specialized versions (e.g., InteractiveImageMedia)
 * - Client wrapper components for specific use cases (galleries, lightboxes)
 */

import type { StaticImageData } from 'next/image'

import { cn } from '@/utils/ui'
import NextImage from 'next/image'
import React from 'react'

import type { Props as MediaProps } from '../types'

// TODO: Replace with centralized breakpoints from config system
// See src/config/ROADMAP.md for the configuration consolidation plan
// Define common image widths for different device types
const breakpoints = {
  l: 1440,
  m: 1024,
  s: 768,
}

export const ImageMedia: React.FC<MediaProps> = (props) => {
  const {
    alt: altFromProps,
    className,
    fill,
    height: heightFromProps, // Extract height from props
    priority = false,
    quality = 85,
    resource,
    sizes: sizesFromProps,
    src: srcFromProps,
    width: widthFromProps, // Extract width from props
  } = props

  let width: number | undefined
  let height: number | undefined
  let alt = altFromProps
  let src: StaticImageData | string = srcFromProps || ''
  let focalX: number | undefined
  let focalY: number | undefined

  if (!src && resource) {
    if (typeof resource === 'object') {
      const {
        height: fullHeight,
        url,
        width: fullWidth,
        alt: resourceAlt,
        focalX: resourceFocalX,
        focalY: resourceFocalY,
      } = resource
      width = fullWidth!
      height = fullHeight!
      if (resourceAlt) alt = resourceAlt
      if (resourceFocalX) focalX = resourceFocalX
      if (resourceFocalY) focalY = resourceFocalY
      src = url?.startsWith('http') ? url : `${process.env.SITE_URL}${url}`
    } else if (typeof resource === 'string') {
      src = resource
    }
  }

  // Generate an appropriate sizes attribute based on the image's intended size
  // This helps the browser select the right image from the srcset
  const sizes = sizesFromProps
    ? sizesFromProps
    : fill
      ? '100vw' // Full viewport width for hero images
      : Object.entries(breakpoints)
          .map(([, value]) => `(max-width: ${value}px) ${value}px`)
          .join(', ') + ', 100vw'

  // Add focal point parameters to the URL if available
  // These will be parsed by our custom loader
  if (focalX !== undefined && focalY !== undefined) {
    const srcString = typeof src === 'string' ? src : '' // Handle StaticImageData
    const separator = srcString.includes('?') ? '&' : '?'
    src = `${srcString}${separator}focalX=${focalX}&focalY=${focalY}`
  }

  // The Next.js Image component will now use our custom loader
  // which is configured globally in the next.config.js
  // Add warning for missing dimensions when using string URL without fill
  if (typeof resource === 'string' && !fill && (!widthFromProps || !heightFromProps)) {
    console.warn(
      'Media component with string URL requires width and height props when fill is not used.',
      { resource }
    )
  }

  return (
    <NextImage
      alt={alt || ''}
      className={cn(className)}
      fill={fill}
      height={!fill ? (height || heightFromProps) : undefined}
      priority={priority}
      quality={quality}
      sizes={sizes}
      src={src}
      width={!fill ? (width || widthFromProps) : undefined}
    />
  )
}
