'use client'

import { cn } from '@/utils/ui'
import React, { useEffect, useRef } from 'react'

import type { Props as MediaProps } from '../types'

export const VideoMedia: React.FC<MediaProps> = (props) => {
  const { resource, fill, className } = props

  const videoRef = useRef<HTMLVideoElement>(null)
  // const [showFallback] = useState<boolean>()

  useEffect(() => {
    const { current: video } = videoRef
    if (video) {
      video.addEventListener('suspend', () => {
        // setShowFallback(true);
        // console.warn('Video was suspended, rendering fallback image.')
      })
    }
  }, [])

  if (resource && typeof resource === 'object') {
    const { filename, url } = resource

    // Determine the correct source URL
    const videoUrl = url?.startsWith('http')
      ? url
      : filename
        ? `${process.env.NEXT_PUBLIC_SERVER_URL || process.env.SITE_URL}/media/${filename}`
        : null

    if (!videoUrl) return null

    return (
      <video
        autoPlay
        className={cn(
          // Apply className as the primary styling
          className,
          // Always apply these styles when fill is true to ensure videos behave like images
          fill && 'absolute inset-0 h-full w-full object-cover',
        )}
        controls={false}
        loop
        muted
        playsInline
        ref={videoRef}
      >
        <source src={videoUrl} />
      </video>
    )
  }

  return null
}
