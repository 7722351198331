/**
 * @fileoverview Utility function to safely check for DOM availability.
 */

/**
 * Checks if the current environment has access to the DOM (Document Object Model).
 * This is useful for determining if the code is running in a browser environment
 * versus a server-side environment like Node.js.
 * 
 * @returns {boolean} Returns true if running in a browser environment with DOM access,
 *                    false otherwise.
 * @example
 * if (canUseDOM()) {
 *   // Safely access DOM APIs
 *   document.getElementById('my-element').innerText = 'Updated!';
 * }
 */
export const canUseDOM = (): boolean => !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
