import { formatURL } from './formatURL'
import { getURL } from './getURL'

/**
 * Custom loader for Next.js Image component using Cloudflare Images.
 * Handles transformations like width, quality, format, and focal point cropping.
 */

/**
 * Interface matching Next.js image loader parameter structure
 * @see https://nextjs.org/docs/api-reference/next/image#loader
 */
interface ImageLoaderProps {
  src: string
  width: number
  quality?: number
}

/**
 * Next.js Image loader for Cloudflare Images
 * Supports width, quality, and focal point handling for optimized cropping
 */
export default function cloudflareLoader({ src, width, quality = 75 }: ImageLoaderProps) {
  // Base URL for the site, used to construct the final absolute image URL
  const siteBaseUrl = getURL() // Uses the refactored getURL

  // Extract focal point parameters if they were included in the src
  // Format expected: src?focalX=50&focalY=50
  let focalX: string | null = null
  let focalY: string | null = null
  let cleanSrc = src

  // Parse URL parameters if present
  if (src.includes('?')) {
    const [urlPath, queryString] = src.split('?')
    cleanSrc = urlPath || ''

    const params = new URLSearchParams(queryString)
    focalX = params.get('focalX')
    focalY = params.get('focalY')
  }

  // Ensure the source URL is absolute using environment variable with fallback
  const rawCloudflarePublicUrl = process.env.CLOUDFLARE_PUBLIC_URL || 'https://cms.brewww.studio'
  // Ensure the Cloudflare public URL is formatted correctly (no trailing slash, respect protocol)
  const cloudflarePublicUrl =
    formatURL(rawCloudflarePublicUrl, { keepTrailingSlash: false, forceHttps: false }) ||
    rawCloudflarePublicUrl

  // Construct the absolute source URL
  let absoluteSrc = cleanSrc.startsWith('http') ? cleanSrc : `${cloudflarePublicUrl}${cleanSrc}`

  // Format the source URL itself (remove trailing slash, query, fragment)
  const sourceUrl =
    formatURL(absoluteSrc, {
      keepTrailingSlash: false,
      forceHttps: false, // Respect original protocol of the source
      preserveQuery: false, // Query params handled separately (focal point)
      preserveFragment: false,
    }) || absoluteSrc

  // Build transformation parameters
  const params = [
    'width=' + width,
    'quality=' + quality,
    // Use fit=crop when we have focal points for better control
    focalX && focalY
      ? `fit=crop,gravity=${(Number(focalX) / 100).toFixed(2)}x${(Number(focalY) / 100).toFixed(2)}`
      : 'fit=cover',
    'format=auto',
    'anim=false', // Ensure static image
  ].join(',')

  // Construct the full absolute URL for the Cloudflare image
  const finalUrl = `${siteBaseUrl}/cdn-cgi/image/${params}/${sourceUrl}`

  // Format the final URL using formatURL for consistency (HTTPS, no double slashes, no trailing slash)
  // Return the original constructed URL as fallback if formatting fails
  return formatURL(finalUrl, { keepTrailingSlash: false, forceHttps: true }) || finalUrl
}
