import React, { ReactElement, HTMLAttributes } from 'react'

import { DefaultNodeTypes } from '@payloadcms/richtext-lexical'
import { SerializedEditorState } from '@payloadcms/richtext-lexical/lexical'
import {
  JSXConvertersFunction,
  LinkJSXConverter,
  RichText as RichTextWithoutBlocks,
} from '@payloadcms/richtext-lexical/react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils/ui'
import typographyPresets, { TypographyPreset } from './presets'
import { SerializedBlockNode, SerializedLinkNode } from '@payloadcms/richtext-lexical'

import type {
  RichCTABlock as RichCTAProps,
  RichMediaBlock as RichMediaProps,
  RichBeforeAfterBlock as RichBeforeAfterProps,
  RichVideoBlock as RichVideoProps,
  RichFAQBlock as RichFAQProps,
} from '@/payload-types'

// Blocks
import { RichMedia } from '@/blocks/RichMedia/Component'
import { RichCTA } from '@/blocks/RichCTA/Component'
import { RichBeforeAfter } from '@/blocks/RichBeforeAfter/Component'
import { RichVideo } from '@/blocks/RichVideo/Component'
import { RichFAQ } from '@/blocks/RichFAQ/Component'

type NodeTypes =
  | DefaultNodeTypes
  | SerializedBlockNode<
      RichCTAProps | RichMediaProps | RichBeforeAfterProps | RichVideoProps | RichFAQProps
    >

const richTextVariants = cva('', {
  variants: {
    enableGutter: {
      true: 'container',
      false: 'max-w-none',
    },
    preset: {
      default: '',
      blogPost: '',
      compact: '',
    },
  },
  defaultVariants: {
    enableGutter: true,
    preset: 'default',
  },
})

type Props = VariantProps<typeof richTextVariants> & {
  data?: SerializedEditorState
  className?: string
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

type HTMLElementProps = HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode
}

function applyPresetClassesToChildren(
  children: React.ReactNode,
  presetClasses: TypographyPreset,
): React.ReactNode {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child

    const element = child as ReactElement<HTMLElementProps>
    const elementType = typeof element.type === 'string' ? element.type : ''

    // Find matching preset class based on element type with type safety
    const presetClass = (() => {
      switch (elementType) {
        case 'p':
          return presetClasses.paragraph
        case 'blockquote':
          return presetClasses.quote
        case 'a':
          return presetClasses.link
        case 'ul':
        case 'ol':
          return presetClasses.list
        case 'li':
          return presetClasses.listItem
        case 'h1':
          return presetClasses.h1
        case 'h2':
          return presetClasses.h2
        case 'h3':
          return presetClasses.h3
        case 'h4':
          return presetClasses.h4
        case 'h5':
          return presetClasses.h5
        case 'h6':
          return presetClasses.h6
        default:
          return ''
      }
    })()

    // Recursively apply to children
    const newChildren = element.props.children
      ? applyPresetClassesToChildren(element.props.children, presetClasses)
      : element.props.children

    // If we have a preset class for this element type, clone and add the class
    if (presetClass) {
      return React.cloneElement<HTMLElementProps>(element, {
        className: cn(element.props.className, presetClass),
        children: newChildren,
      })
    }

    // If no preset class but has children, just clone with new children
    if (newChildren !== element.props.children) {
      return React.cloneElement<HTMLElementProps>(element, { children: newChildren })
    }

    // Otherwise return unchanged
    return element
  })
}

const internalDocToHref = ({ linkNode }: { linkNode: SerializedLinkNode }) => {
  const { value, relationTo } = linkNode.fields.doc!
  if (typeof value !== 'object') {
    throw new Error('Expected value to be an object')
  }
  const slug = value.slug
  return relationTo === 'posts' ? `/posts/${slug}` : `/${slug}`
}

export function RichText(props: Props) {
  const { className, enableGutter = true, preset = 'default', data, children, ...rest } = props
  const presetClasses = typographyPresets[preset as keyof typeof typographyPresets]

  const customConverters: JSXConvertersFunction<NodeTypes> = ({ defaultConverters }) => ({
    ...defaultConverters,
    ...LinkJSXConverter({ internalDocToHref }),
    paragraph: ({ node, nodesToJSX }) => (
      <p className={presetClasses.paragraph}>{nodesToJSX({ nodes: node.children })}</p>
    ),
    heading: ({ node, nodesToJSX }) => {
      const Tag = node.tag as keyof Pick<TypographyPreset, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>
      return <Tag className={presetClasses[Tag]}>{nodesToJSX({ nodes: node.children })}</Tag>
    },
    list: ({ node, nodesToJSX }) => {
      const Tag = node.tag as 'ul' | 'ol'
      return <Tag className={presetClasses.list}>{nodesToJSX({ nodes: node.children })}</Tag>
    },
    listitem: ({ node, nodesToJSX }) => (
      <li className={presetClasses.listItem}>{nodesToJSX({ nodes: node.children })}</li>
    ),
    quote: ({ node, nodesToJSX }) => (
      <blockquote className={presetClasses.quote}>
        {nodesToJSX({ nodes: node.children })}
      </blockquote>
    ),
    link: ({ node, nodesToJSX }) => (
      <a href={node.fields?.url} className={presetClasses.link}>
        {nodesToJSX({ nodes: node.children })}
      </a>
    ),
    blocks: {
      richMedia: ({ node }) => <RichMedia className="col-start-2" {...node.fields} />,
      richCTA: ({ node }) => (
        <div className="col-start-2">
          <RichCTA {...node.fields} />
        </div>
      ),
      richBeforeAfter: ({ node }) => (
        <div className="col-start-2">
          <RichBeforeAfter {...node.fields} />
        </div>
      ),
      richVideo: ({ node }) => (
        <div className="col-start-2">
          <RichVideo {...node.fields} />
        </div>
      ),
      richFAQ: ({ node }) => (
        <div className="col-start-2">
          <RichFAQ {...node.fields} />
        </div>
      ),
    },
  })

  return (
    <div className={cn(richTextVariants({ enableGutter, preset }), className)} {...rest}>
      {data ? (
        <RichTextWithoutBlocks data={data} converters={customConverters} />
      ) : (
        applyPresetClassesToChildren(children, presetClasses)
      )}
    </div>
  )
}
