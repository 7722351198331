'use client'

import React from 'react'
import { cn } from '@/utils/ui'
import type { RichFAQBlock as RichFAQProps } from '@/payload-types'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/Accordion'
import { RichText } from '@/components/core/RichText'

type Props = {
  className?: string
} & RichFAQProps

export const RichFAQ: React.FC<Props> = (props) => {
  const { className, faqItems } = props

  if (!faqItems || faqItems.length === 0) return null

  return (
    <div className={cn('my-8 w-full', className)}>
      <Accordion type="single" collapsible className="w-full">
        {faqItems.map((item, index) => (
          <AccordionItem
            key={index}
            value={`faq-${index}`}
            triggerVariant="heading"
            layout="tabbed"
          >
            <AccordionTrigger>{item.question}</AccordionTrigger>
            <AccordionContent>
              <div className="prose prose-gray dark:prose-invert max-w-none">
                <RichText data={item.answer} />
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
