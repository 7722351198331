import React, { Fragment } from 'react'
import { cn } from '@/utils/ui'

import type { Props } from './types'

import { ImageMedia } from './ImageMedia'
import { VideoMedia } from './VideoMedia'

export const Media: React.FC<Props> = (props) => {
  const { className, htmlElement = 'div', resource, fill } = props

  // Improved video detection - check both mimeType and filename extension
  const isVideo =
    typeof resource === 'object' &&
    ((resource?.mimeType && resource.mimeType.includes('video')) ||
      (resource?.filename && /\.(mp4|webm|ogg|mov)$/i.test(resource.filename)))

  const Tag = (htmlElement as React.ElementType | null) || Fragment

  return (
    <Tag
      {...(htmlElement !== null
        ? {
            className: cn(className, fill && 'relative h-full w-full'),
          }
        : {})}
    >
      {isVideo ? <VideoMedia {...props} /> : <ImageMedia {...props} />}
    </Tag>
  )
}
